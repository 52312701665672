.App {
  text-align: center;
}

.control-buttons {
  position: relative;
  z-index: 1000;
}

.container {
  margin: 20px auto;
  position: relative;
  /* width: fit-content; */
  /* block-size: fit-content; */
  width: 100%;
  /* height: fit-content;; */
  height: 720px;
  /* width: 1280px; */
  overflow: hidden;
}
